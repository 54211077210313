
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  font-family: 'Montserrat', sans-serif;
  background-color: whitesmoke;
}

.hero, #page-not-found, #aboutHeader{
  position: relative;
  top:0;
  left: 0;
  height: 80vh;
  width: 100%;
  align-items: center;
  background-color: whitesmoke;
}
#page-not-found h1{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  /*transform: translate(-50%, -50%);*/
  width:auto;
  display:inline-block;
}

h1, h2, h3, h4{
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
  margin: 2rem 0;
}
h1{
  letter-spacing: 10px;
  font-size: 40px;
  padding: 2rem 0;
}
h2{
  letter-spacing: 6px;
  font-size: 24px;
}
h3{
  padding: 1rem 0;
}
h4{
  letter-spacing: 5px;
  font-size: 20px;
  padding-bottom: 5px;
}
h5{
  letter-spacing: 4px;
  font-size: 16px;
}

#projects, #otherProjects{
  position: relative;
  width: 100%;
  background: whitesmoke;
  color: black;
  display: flex;
  justify-content: space-around;
}
#otherProjects .projectItems{
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}
#otherProjects .itemtext{
  display: none;
}

#projects h2, h3, h4{
  margin:0;
}   
.contentWidth{
  width: 65%;
}
@media only screen and (max-width: 1440px) {
  .contentWidth{
    width: 80%;
  }
}
@media only screen and (max-width: 1200px) {
  .contentWidth{
    width: 90%;
  }
}
@media only screen and (max-width: 1000px) {
  .contentWidth{
    width: 100%;
  }
  p, h1, h2, h3, h4, h5{
    padding-left: 2.5vw;
    padding-right: 2.5vw;
  }
  .side-content-grid{
    margin-left: 2.5vw;
    margin-right: 2.5vw;
  }
}
@media only screen and (max-width: 500px) {

  .contentWidth{
    width: 100%;
  }
  h1, h2, h3, h4{
    text-align: center;
  }
  p{
    padding-left: 2.5vw;
    padding-right: 2.5vw;
  }
  h1{
    letter-spacing: 7px;
    font-size: 30px;
    padding: 2rem 0;
  }
}
.projectItems{
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.item{
  position: relative;
  display: grid;
  /*place-items: center;*/
  overflow: hidden;   
  justify-content: space-around;
}
.itemtext {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-decoration: none;
  color: black;
}
.itemblur{
  position: absolute;
  width: 100%;
  height: 100%;
  background: whitesmoke;

  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  -moz-opacity: 0.8;
  -khtml-opacity: 0.8;
  opacity: 0.8;
}
.item img{
  width: 100%;
}
p{
  margin-bottom: 1em;
}