footer{
    position: relative;
    display: block;
    bottom:0;
    margin: auto;
    width: 20%;
    min-height: 10vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 10;
  }
  .social{
    list-style: none;
    text-decoration: none;
    color: black;
    padding: 0 20px;
    font-size: 16px;
  }
  .social:hover{
    opacity: 50%;
  }