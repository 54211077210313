nav {
    top:0;
    width: 100%;
    min-height: 10vh;
    position: absolute;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: rgba(245, 245, 245,0.7);
    color: black;
    z-index: 10000;
}
.nav-links{
    display: flex;
    align-items: right;
    list-style: none;
}
.nav-links a{
    text-decoration: none;
    font-weight: bold;
    color: black;
    text-transform: uppercase;
    padding: 0 20px;
    letter-spacing: 4px;
    font-size: 16px;
}
.nav-links a:hover{
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  -moz-opacity: 0.5;
  -khtml-opacity: 0.5;
  opacity: 0.5;
}
#nav-logo{
    padding: 0 20px;
}
#nav-logo a{
    text-decoration: none;
    font-weight: bold;
    color: black;
}
@media only screen and (max-width: 600px) {
    nav {
        display: grid;
        margin: auto;
    }
    .nav-links{
        text-align: center;
        margin: auto;
        padding: 0;
    }
    #nav-logo{
        text-align: center;
        margin: auto;
        padding: 0;
    }
  }