
.projectHeader{
    top: 0;
    left: 0;
    width: 100%;
    height: 50vh;
    background-color: whitesmoke;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}
.projectSection{
    position: relative;
    height: 100%;
    width: 100%;
    align-items: center;
    background-color: whitesmoke;
}
.projectSection h1{
    margin: auto;
}

#fjallstugan .content,
#about .content{
    display: grid;
    grid-gap: 2rem;
    /*grid-template-columns: repeat(auto-fit, minmax(300px, 2fr));*/
    grid-template-columns: 1fr auto;
    margin: auto;
}
  
.sticky{
    position: -webkit-sticky;
    position: sticky;
    place-items: center;
    overflow: hidden;   
    justify-content: space-around;
    top: 5vh;
    width: 100%;
    height: 90vh;
}
.CrossfadeImage{
    margin-left: auto;
    right: 0;
    display: block;
    height: 100%;
    object-fit: contain;
}
#fjallstugan .contentText,
#about .contentText{
    position: relative;
    padding: 15vh 0 ;
}
.content{
    margin: auto;
}
.content-grid{
    display: grid;
    grid-gap: 2rem;
    /*grid-template-columns: repeat(auto-fit, minmax(300px, 2fr));*/
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    margin: auto;
}
.side-content-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.side-content-grid div{
    margin-bottom: 16px;
}
.side-content-grid p{
    padding: 0;
    margin: 0;
}
.content-grid-images{
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(200px, 2fr));
    margin: auto;
    justify-content: space-around;
}
ul {
    display: inline-block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}
li {
    display: list-item;
    text-align: -webkit-match-parent;
}
.colImg{
    width: 100%;
}
.newImg{
    display: none;
    max-width: 100vw;
    max-height: 90vh;
    margin: auto;
}
@media only screen and (max-width: 450px){
    .newImg, .colImg{
        max-width: 90vw;
        max-height: 90vh;
        margin: auto;
    }
}
@media only screen and (max-width: 900px) {
    #fjallstugan .newImg,
    #about .newImg{
        display: block;
    }
    #fjallstugan .content,
    #about .content{
        display: block;
    }
    #fjallstugan .contentText,
    #about .contentText{
        padding: 0;
    }
    .sticky{
        display: none;
    }
  }